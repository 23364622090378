import React from "react"

import { SEO, Layout } from "components"
import { Header, About, MenuSection, Review } from "sections"

const IndexPage = () => (
  <Layout>
    <SEO />
    <Header />
    <About />
    <Review />
    <MenuSection />
  </Layout>
)

export default IndexPage
